<template>
  <common-card>
    <v-toolbar color="primary" dense dark flat>
      <v-toolbar-title>
        <span class="font-montserrat font-weight-bold"
          >Reporte de dispersión para pago de Comisión a Asesores</span
        >
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="mb-15 fill-height">
        <v-col>
          <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
          <v-row class="mb-15" style="padding-left: 30px">
            <v-col md="3">
              <h3>Selecciona el rango de fechas</h3>
              <v-date-picker
                style="width: 100%"
                v-model="dates"
                range
              ></v-date-picker>
              <v-btn
                rounded
                color="primary"
                style="width: 100%"
                dark
                @click="consultar"
                >Consultar</v-btn
              >
              <v-btn
                rounded
                color="primary"
                style="width:100%;margin-top:20px;"
                :disabled="bloquear"
                ><download-csv
                  :data="comisiones"
                  name="Reporte_pago_comisiones_aplicados.csv"
                >
                  Descargar Información
                </download-csv></v-btn
              >
            </v-col>
            <v-col cols="9" md="9">
              <div>
                <div v-if="loading" style="text-align: center">
                  <h2 clas="pb-4">Cargando Reporte....</h2>
                  <br /><br />
                  <md-progress-spinner
                    :md-diameter="100"
                    :md-stroke="10"
                    md-mode="indeterminate"
                  ></md-progress-spinner>
                </div>
              </div>

              <v-data-table
                :headers="columns"
                :items="comisiones"
                item-key="ID Cuota"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    class="mx-4"
                    outlined
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </common-card>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { mainAxios } from "../../../../mainAxios";

var moment = require("moment-timezone");

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      comisiones: [],
      search: "",
      columns: [
        { text: "ID Cuota", value: "ID Cuota" },
        { text: "Asesor", value: "Asesor" },
        { text: "Importe a pagar", value: "Importe a pagar" },
        { text: "Mail", value: "Mail" },
      ],
      bloquear:true,
      periodicidadList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
    };
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    consultar() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let inicioUTC = null;
      let finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }
      let fechas = {
        fechaInicio: inicioUTC,
        fechaFin: finUTC,
      };
      mainAxios
        .post(
          `v1/comision/dispercion/asesores`,
          fechas,
          config
        )
        .then((value) => {
          this.comisiones = []
          value.data.comisiones.map((e) =>{
           this.comisiones.push({
            'ID Cuota': e.id,
            'Asesor': e.nombreAgente,
            'Importe a pagar':this.formatPrice(e.importeAPagarAgente),
            'Mail':e.correoAgente,
           }) 
          })
            this.bloquear = false;
          });
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
  },
};
</script>
